@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Beiruti';
  src: local('Beiruti'), url(./assets/fonts/Beiruti-VariableFont_wght.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'cairo';
  src: local('cairo'), url(./assets/fonts/Cairo-VariableFont_slnt,wght.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: "Beiruti", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: "cairo", sans-serif;

}

.force-ltr {
  direction: ltr !important;
}

.force-rtl {
  direction: rtl !important;

}

.overlay {
  z-index: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 990px;
}

.overlay .containerM {
  display: block;
  position: initial;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
}

.cat-item.hide-animation {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  position: initial !important;
  transition: all .5s ease-in-out;
  margin: 0 !important;

}

.cat-item.hide-animation * {
  margin: 0 !important;
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  position: initial !important;
  transition: all .5s ease-in-out;
  line-height: 0 !important;
}

.swiper-button-next, .swiper-button-prev {
  top: 0px !important;
  margin-top: auto !important;
  padding-top: 10px !important;

}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 15px !important;
  color: #ca7d20 !important
}

.swiper-slide {
  /* max-height: 79dvh !important; */
  overflow-y: auto !important;
}
.height-inherit{
  height: inherit!important;
}
/* header{
  height: 14dvh;
} */
main{
  /* height:79dvh; */
  overflow-y: auto !important;
}
/* footer{
  height: 5dvh;
} */
/* .mySwiper {
  max-height: 83vh !important;
  overflow-y: auto !important;

} */
::-webkit-scrollbar {
  display: none;
}
